/* In src/App.css */
.settings-toggle {
  position: fixed;
  top: 20px;
  right: 40px; /* This should match the padding-right of .settings-content */
  width: px;
  height: 40px;
  background-color: #f0f0f0;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;
  transition: background-color 0.3s, color 0.3s;
}

.settings-toggle:hover {
  background-color: #e0e0e0;
}

.settings-toggle.active {
  background-color: #d0d0d0;
  color: #000;
}