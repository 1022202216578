.insights-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 1280px;
    height: 720px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .insights-modal h2, .insights-modal h3 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  .insights-modal textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    resize: vertical;
    min-height: 100px;
    box-sizing: border-box;
  }
  
  .insights-modal .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }
  
  .insights-modal button {
    padding: 8px 15px;
    background-color: #f0f0f0;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    width: auto;
    min-width: 80px;
  }
  
  .insights-modal button:hover {
    background-color: #e0e0e0;
  }
  
  .insights-modal .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .insights-modal .response {
    margin-top: 20px;
  }
  
  .insights-modal .response-text {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    min-height: 150px;
    resize: none;
    overflow: hidden;
  }