/* src/SettingsSidebar.css */
.settings-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.settings-content {
  flex: 1;
  overflow-y: auto;
}

.settings-header {
  margin-bottom: 20px;
}

.settings-header h2 {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
}

.settings-section {
  margin-bottom: 30px;
}

.settings-section h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.settings-section h4 {
  margin: 15px 0 10px;
  font-size: 16px;
  color: #444;
}

.settings-section p, .settings-section ul {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  margin-bottom: 10px;
}

.settings-section ul {
  padding-left: 20px;
}

.settings-section li {
  margin-bottom: 5px;
}

.logout-container {
  padding: 20px 0;
  text-align: right;
}

.logout-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #e0e0e0;
}