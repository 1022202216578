/* src/Calendar.css */
.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.calendar-grid {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    align-items: center;
}

.day-label {
    width: 100px;
    text-align: right;
    margin-right: 20px;
}

.week {
    display: flex;
    flex-direction: row;
}