.box {
    width: 20px;
    height: 20px;
    margin: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-sizing: border-box;
  }
  
  .box.disabled {
    cursor: default;
    opacity: 0.5;
  }
  
  .box.today {
    border: 1px solid black;
  }
  
  .box.insight {
    background-color: #f0f0f0; /* Light grey for sum boxes without insights */
  }
  
  .box.insight.has-insight {
    background-color: #ffd700; /* Gold for sum boxes with insights */
  }