.insights-modal-monthly {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 80%;
    max-width: 600px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .insights-modal-monthly h2, .insights-modal-monthly h3 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  .insights-modal-monthly textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    resize: vertical;
    min-height: 100px;
    box-sizing: border-box;
  }
  
  .insights-modal-monthly button {
    padding: 8px 15px;
    border: 1px solid #e0e0e0;
    background-color: white;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    margin-bottom: 10px;
  }
  
  .insights-modal-monthly button:hover {
    background-color: #f0f0f0;
  }
  
  .insights-modal-monthly .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .insights-modal-monthly .response {
    margin-top: 20px;
  }
  
  .insights-modal-monthly .response-text {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    min-height: 150px;
    resize: none;
    overflow: hidden;
  }