/* src/JournalEntryModal.css */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 80%;
    max-width: 1280px;
    height: 80%;
    max-height: 720px;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease;
  }
  
  .modal.show-preview {
    width: 95%;
  }
  
  .entry-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .editor-preview-container {
    display: flex;
    flex: 1;
    gap: 20px;
    overflow: hidden;
  }
  
  textarea, .preview-container {
    flex: 1;
    overflow-y: auto;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  textarea {
    resize: none;
    width: 100%;
  }
  
  .preview-container {
    background-color: #f9f9f9;
    display: none;
  }
  
  .show-preview .preview-container {
    display: block;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
  
  .word-count {
    font-size: 14px;
  }
  
  .save-status {
    font-size: 14px;
    color: #28a745; /* Green color for success */
    margin-right: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .save-status.active {
    opacity: 1;
  }
  
  @keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
  }
  
  .save-status:contains("SAVING...") {
    animation: pulse 1s infinite;
  }
  
  .button-container {
    display: flex;
    gap: 10px;
  }
  
  button {
    margin: 5px;
    padding: 8px 15px;
    background-color: #f0f0f0;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover:not(:disabled) {
    background-color: #e0e0e0;
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }